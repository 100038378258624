/**
 * @module Footer
 * @description Site Footer
 */
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import state from '@/js/config/state';
import Accordion from '@/js/components/accordion';
import SiteLegal from '@/providers/content-provider/components/SiteLegal';
import useProfile from '@/hooks/useProfile';

/**
 * @function Footer
 * @description Default Footer
 */
const Footer = ( { ...props } ) => {
  const [ loggedIn, setLoggedIn ] = useState( false );
  const { fetchProfile } = useProfile();

  const defaults = {
    menus: [
      {
        title: 'Shop',
        links: [
          { href: '/shop/deals', title: 'Deals' },
          { href: '/shop', title: 'Plans' },
          { href: '/shop/internet', title: 'Internet' },
          { href: '/shop/internet/fiber-internet', title: 'Fiber Internet' },
          { href: '/shop/tv', title: 'TV & Streaming' },
          { href: '/shop/phone', title: 'Phone' },
          { href: '/shop/additional-services', title: 'Additional Services' },
          { href: '/resources/movers', title: 'Moving' },
          { href: '/local', title: 'Availability' }
        ]
      },
      {
        title: 'My Account',
        links: [
          { href: '/resources/frontier-id-registration?icid=18jun18_national_homepage_register_footer_page_link', title: 'Register' },
          { href: loggedIn ? '/account#/payments/onetime' : '/login', title: 'Pay Bill' },
          { href: '/expresspay', title: 'Express Pay' },
          { href: loggedIn ? '/account#/summary' : '/login', title: 'Manage Account' },
          { href: '/resources/myfrontier-mobile-app', title: 'MyFrontier App' },
          { href: 'https://www.frontiercustomerreferral.com/', title: 'Refer Friends & Family' }
        ]
      },
      {
        title: 'Support',
        links: [
          { href: '/helpcenter', title: 'Help Center' },
          { href: '/helpcenter/internet/troubleshooting/check-for-service-outages', title: 'Check for outages' },
          { href: 'https://videos.frontier.com/', title: 'How-To Videos' },
          { href: '/helpcenter/order-ticket-status', title: 'Order/Ticket Status' },
          { href: '/contact-us', title: 'Contact Us' }
        ]
      },
      {
        title: 'Corporate',
        links: [
          { href: 'https://newsroom.frontier.com/leadership/', title: 'Company' },
          { href: 'https://investor.frontier.com/corporate-profile/default.aspx', title: 'Investors' },
          { href: 'https://newsroom.frontier.com', title: 'News' },
          { href: 'https://blog.frontier.com/', title: 'Blog' },
          { href: '/corporate/responsibility/home', title: 'Responsibility' },
          { href: 'https://newsroom.frontier.com/suppliers/', title: 'Suppliers' },
          { href: '/discount-programs', title: 'Discount Programs' },
          { href: 'https://frontier-careers.com/', title: 'Careers' }
        ]
      },
      {
        title: 'Frontier Sites',
        links: [
          { href: 'https://business.frontier.com/', title: 'Small Business' },
          { href: 'https://enterprise.frontier.com/', title: 'Enterprise' },
          { href: 'https://wholesale.frontier.com/home', title: 'Wholesale' },
          { href: '/resources/multifamily', title: 'Multifamily' },
          { href: '/frontieragent', title: 'Frontier Business Partner Program' },
          { href: '/resources/white-pages', title: 'Phone Directories' }
        ]
      },
      {
        title: 'Sales Partners',
        links: [
          { href: 'https://go.frontier.com/', title: 'Frontier Fiber' },
          { href: 'https://www.frontierbundles.com/availability', title: 'Local Internet' }
        ]
      }
    ],
    footerLinks: [
      { href: '/', title: 'Home' },
      { href: 'https://frontier.com/page-sitemap', title: 'Site Map' },
      { href: 'https://frontier.com/corporate/policies', title: 'Policies & Notifications' },
      { href: 'https://frontier.com/corporate/terms', title: 'Terms & Conditions' },
      { href: 'https://frontier.com/corporate/privacy-policy', title: 'Privacy Policy' },
      { href: 'https://frontier.com/corporate/privacy-policy-california', title: 'California Privacy Policy' },
      { href: 'https://frontier.com/corporate/privacy-policy-connecticut-texas', title: 'CT, TX Privacy Policy' }
    ],
    socialLinks: [
      { icon: 'icon-twitter-x', href: 'https://twitter.com/FrontierCorp', title: 'Twitter' },
      { icon: 'icon-facebook', href: 'https://www.facebook.com/FrontierCorp', title: 'Facebook' },
      { icon: 'icon-linkedin', href: 'https://www.linkedin.com/company/163906/', title: 'LinkedIn' },
      { icon: 'icon-youtube', href: 'https://www.youtube.com/user/FrontierCorp', title: 'YouTube' },
      { icon: 'icon-instagram', href: 'https://www.instagram.com/frontierinternet/', title: 'Instagram' }
    ]
  };

  const {
    menus = defaults.menus, footerLinks = defaults.footerLinks, socialLinks = defaults.socialLinks
  } = props;

  const el = useRef( null );

  /**
   * @function setA11y
   * @description Update aria attributes on desktop/mobile
   */
  const setA11y = () => {
    const footer = document.querySelector( '.footer-menu' );
    const btns = footer.querySelectorAll( '.accordion-header' );

    btns.forEach( ( btn ) => {
      const { parentNode } = btn;

      if ( state.is_desktop === true ) {
        btn.setAttribute( 'aria-expanded', true );
        btn.setAttribute( 'aria-disabled', true );
        return;
      }

      if ( parentNode.classList.contains( '--active' ) ) {
        btn.setAttribute( 'aria-expanded', true );
      } else {
        btn.setAttribute( 'aria-expanded', false );
      }

      btn.removeAttribute( 'aria-disabled' );
    } );
  };

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    new Accordion( el.current );
    document.addEventListener( 'redventures/resize_executed', setA11y );
  }, [ ] );

  useEffect( () => {
    fetchProfile( )
      .then( ( data ) => {
        if ( data ) setLoggedIn( true );
      } );
  }, [ fetchProfile ] );

  return <>
    <footer id="colophon" className="site-footer footer-region u-theme-light" role="contentinfo">
      <div className="site-footer-primary">
        <nav className="menu footer-menu footer-menu-primary" aria-label="Footer Primary Menu">
          <div ref={ el }className="l-container accordion" data-accordion data-accordion-toggle="true">

            { menus.map( ( menu, index ) => (
                <div key={ index } className="accordion-item" data-index={ index }>
                  <button className="accordion-header" role="button" aria-expanded="false" aria-controls={ `footer-menu-column-${ index }` }>
                    <div className="accordion-title">{ menu.title }</div>
                  </button>
                  <div className="accordion-content" id={ `footer-menu-column-${ index }` }>
                    <ul className="menu">
                      { menu.links.map( ( link, index1 ) => (
                          <li key={ index1 }><Link href={ link.href } target={ link.target }>{ link.title }</Link></li>
                      ) ) }
                    </ul>
                  </div>
                </div>
            ) ) } { /* <!-- .accordion-item --> */ }

          </div>{ /* <!-- .accordion--> */ }
        </nav>
      </div>{ /* .site-footer-primary */ }

      <div className="site-footer-secondary">
        <SiteLegal />
        <p className="site-copyright u-color-midnight">&copy; { new Date().getFullYear() } Frontier Communications Parent, Inc. All rights reserved.</p>

        <nav className="footer-menu footer-menu-secondary" aria-label="Footer Secondary Menu">
          <ul className="menu">
            { footerLinks.map( ( link, index ) => (
                <li key={ index }><Link href={ link.href } target={ link.target }>{ link.title }</Link></li>
            ) ) }

            { /* Launches cookie consent pop-up (Needs trailing space to avoid hydration error) */ }
            <li id="ot-sdk-btn" className="ot-sdk-show-settings">Do Not Sell or Share My Personal Information </li> { /* eslint-disable-line no-irregular-whitespace */ }
          </ul>
        </nav>

        <nav className="social" aria-label="Social Menu">
          <ul className="menu social-menu">
            { socialLinks.map( ( link, index ) => (
                <li key={ index }><Link
                  href={ link.href }
                  target="_blank"
                  className={ `icon ${ link.icon }` }
                  title={ link.title }>{ link.title }</Link></li>
            ) ) }
          </ul>
        </nav>{ /* <!-- .social --> */ }
      </div>{ /* .site-footer-secondary */ }
    </footer>{ /* <!-- .site-footer --> */ }
  </>;
};

Footer.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape( {
          href: PropTypes.string,
          title: PropTypes.string,
          target: PropTypes.string
        } )
      )
    } )
  ),
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string
    } )
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      icon: PropTypes.string,
      href: PropTypes.string,
      title: PropTypes.string
    } )
  )
};

export default Footer;
